// This SHOULD work inline reactively, but for some reasion I had
// to still clone the array and return a new one. I'm not sure why
// this is the case, but I'm not going to spend time on it right now.
// I'll come back to it later if I have time.
const swapItems = (array: any[], index1: number, index2: number): any[] => {
  const newArray = [...array]

  const temp = array[index1]
  array[index1] = array[index2]
  array[index2] = temp

  return newArray
}

export default swapItems
